@import "@grapecity/activereports/styles/ar-js-ui.css";
@import "@grapecity/activereports/styles/ar-js-designer.css";
@import "@grapecity/activereports/styles/ar-js-viewer.css";

@font-face {
    font-family: "Inter-Light";
    src: local("Inter-Light"), url("../font/Inter-Light.ttf") format("truetype");
    font-weight: normal;
  }
  
  @font-face {
    font-family: "Inter-Bold";
    src: local("Inter-Bold"),
      url("../font/Inter-Bold.ttf") format("truetype");
    font-weight: normal;
  }
  
  @font-face {
    font-family: "Inter-Medium";
    src: local("Inter-Medium"), url("../font/Inter-Medium.ttf") format("truetype");
    font-weight: normal;
  }

  @font-face {
    font-family: "Inter";
    src: local("Inter"), url("../font/Inter.ttf") format("truetype");
    font-weight: normal;
  }

body{
    margin: 0px;
    padding: 0px;
    font-family: "Inter-Medium" !important;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch {
  width: 32px !important;
  height: 16px !important;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px !important;
  width: 13px !important;
  left: 4px;
  bottom: 1.8px !important;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px) !important;
  -ms-transform: translateX(13px);
  transform: translateX(13px) !important;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.delete-button{
  position: relative;
    cursor: pointer;
    display: contents;
}
.delete-button::before{
  content: '';
  position: absolute;
  top: 22%;
  background-image: url(../assets/delete.svg);
  width: 16px;
  height: 16px;
  background-size: contain;
  left: 20px;
  transform: translateX(-50%);
}